import React, { useState, useEffect, useRef } from "react";
import Masonry from "react-masonry-css";

const InfiniteMasonryPhotoWall = ({ images, dynamicId, settings }) => {
  const [displayedImages, setDisplayedImages] = useState(images);
  const containerRef = useRef(null);
  const animationFrame = useRef(null);

  // Update displayed images when new images arrive
  useEffect(() => {
    if (images.length === 0) {
      setDisplayedImages([]);
    } else {
      setDisplayedImages((prevImages) => {
        const uniqueImages = [...new Set([...prevImages, ...images])];
        return uniqueImages;
      });
    }
  }, [images]);

  // Auto-scroll functionality
  useEffect(() => {
    const scrollSpeed = 0.5;
    const smoothScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;

        // Increment scroll position
        container.scrollTop += scrollSpeed;

        // Reset scroll to top if scrolled past the first set of images
        if (container.scrollTop >= container.scrollHeight / 2) {
          container.scrollTop = 0;
        }
      }

      animationFrame.current = requestAnimationFrame(smoothScroll);
    };

    animationFrame.current = requestAnimationFrame(smoothScroll);

    // Cleanup animation frame on unmount
    return () => cancelAnimationFrame(animationFrame.current);
  }, []);

  // Masonry breakpoint columns for responsive design
  const masonryBreakpoints = {
    default: 5, // Adjusted to fewer columns for smaller datasets
    1100: 4,
    700: 3,
    500: 2,
  };

  // Ensure sufficient images for a balanced layout
  const minImageCount = 15; // Minimum number of images to fill the screen
  const imagesToDisplay =
    displayedImages.length < minImageCount
      ? Array(minImageCount)
          .fill(0)
          .flatMap(() => displayedImages)
      : [...displayedImages, ...displayedImages];

  return (
    <div
      ref={containerRef}
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        position: "relative",
        scrollbarWidth: "none", // Hide scrollbar for Firefox
        msOverflowStyle: "none", // Hide scrollbar for IE/Edge
        zIndex: "-2", // Hide scrollbar for IE/Edge
      }}
      className="scroll-hidden" // Additional class for WebKit browsers
    >
      <Masonry
        breakpointCols={masonryBreakpoints}
        className="photo-wall-masonry-grid"
        columnClassName="photo-wall-masonry-column"
      >
        {imagesToDisplay.map((image, index) => (
          <div
            key={`image-${index}`}
            className="photo-wall-image"
            style={{
              backgroundImage: `url(https://storage.googleapis.com/${dynamicId}/thumbnails/${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "10px",
              marginBottom: "10px",
              width: "100%",
              paddingTop: "100%", // Square aspect ratio
              filter: settings.filterBlur,
            }}
          />
        ))}
      </Masonry>
    </div>
  );
};

export default InfiniteMasonryPhotoWall;
